/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Box } from "@theme-ui/components"

import ShadowAnimation from "../animations/shadow-animation"

// Plantilla de dashboard que consiste en dos filas,
// cada una con dos columnas en proporciones 1fr 2fr
const TotalDashboardView = ({
  headerFirst,
  headerSecond,
  bodyFirst,
  bodySecond,
  loadedData,
}) => {
  // const [colorMode] = useColorMode()
  return (
    <>
      <div sx={{ variant: "styles.contentDashboard" }}>
        {!loadedData && <ShadowAnimation dashboard />}
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row", "row"],
            justifyContent: "space-between",
            // gridGap: 6, // theme.space[4]
            // use arrays for mobile-first responsive styles
            // gridTemplateColumns: [
            //   "auto", // default to a stacked layout on small screens
            //   "1fr 2fr", // use columns for larger screens
            //   // "300px 1fr", // use columns for larger screens
            // ],
          }}
        >
          <Box
            // sx={{ px: 4, paddingTop: 4, paddingBottom: 0 }}
            sx={{
              display: "flex",
              flexDirection: ["column", "row", "column", "column"],
              justifyContent: "space-between",
              textAlign: ["center", "center", "start", "end"],
              pt: [4, 4, 4, 4],
              pb: [3, 2, 2, 2],
              width: ["100%", "100%", "30%", "30%"],
            }}
          >
            {headerFirst}
          </Box>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row", "row", "row"],
              justifyContent: "space-between",
              alignSelf: "flex-end",
              // textAlign: ["center", "center", "start", "end"],
              width: ["100%", "100%", "60%", "60%"],
            }}
          >
            {headerSecond}
          </div>
        </div>

        <div
          sx={{
            pt: 2,
            pb: [4, 2, 2, 2],
            display: "grid",
            gridGap: 6, // theme.space[4]
            // use arrays for mobile-first responsive styles
            gridTemplateColumns: [
              "auto", // default to a stacked layout on small screens
              "1fr 2fr", // use columns for larger screens
              // "300px 1fr", // use columns for larger screens
            ],
          }}
        >
          <Box>{bodyFirst}</Box>
          <Box
          // bg={colorMode === "dark" ? "#0a0a0a" : "#fafafa"}
          // px={3}
          // py={3}
          // color={colorMode === "dark" ? "white" : "black"}
          // sx={{
          //   border: `2px solid ${colorMode === "dark" ? "#141414" : "#f5f5f5"}`,
          //   borderRadius: 8,
          // }}
          >
            {bodySecond}
          </Box>
        </div>
      </div>
    </>
  )
}

export default TotalDashboardView
