/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect, useRef } from "react"

import TotalDashboardView from "../views/total-dashboard-view"

import MainIndicator from "../widgets/main-indicator"
import SecondaryIndicator from "../widgets/secondary-indicator"
import ZoomChart from "../charts/zoom-chart"
import HeatmapCalendar from "../charts/heatmap-calendar"

const TotalDataDashboard = ({ type, data }) => {
  const [variationData, setVariationData] = useState([])

  useEffect(() => {
    // prepare data variation for heatmap
    if (data && data.totalData) {
      const prepared = data.totalData.map((row, index) => {
        if (index + 1 < data.totalData.length) {
          return [new Date(row.timestamp), row.n - data.totalData[index + 1].n]
        }
        return
      })
      prepared.pop()
      setVariationData(prepared)
    }
  }, [data])

  //  Heatmap wrapper dimensions

  const targetRef = useRef()
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }, [])

  return (
    <TotalDashboardView
      loadedData={data && data.totalData && data.totalData.length > 0}
      headerFirst={
        <MainIndicator
          title={"Total Data"}
          amount={
            data &&
            data.totalDataMetrics &&
            data.totalDataMetrics[0].total &&
            data.totalDataMetrics[0].total.toLocaleString("en")
          }
          type={type}
        />
      }
      headerSecond={
        <>
          <SecondaryIndicator
            title={"Current Month"}
            amount={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].current_month &&
              data.totalDataMetrics[0].current_month.toLocaleString("en")
            }
            change={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].gr_current_month &&
              data.totalDataMetrics[0].gr_current_month.toLocaleString("en")
            }
            since={"since last month"}
            previous={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].same_day_hour_last_month &&
              data.totalDataMetrics[0].same_day_hour_last_month.toLocaleString(
                "en"
              )
            }
          />
          <SecondaryIndicator
            title={"Current Week"}
            amount={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].current_week &&
              data.totalDataMetrics[0].current_week.toLocaleString("en")
            }
            change={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].gr_current_week &&
              data.totalDataMetrics[0].gr_current_week.toLocaleString("en")
            }
            since={"since last week"}
            previous={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].same_dow_hour_last_week &&
              data.totalDataMetrics[0].same_dow_hour_last_week.toLocaleString(
                "en"
              )
            }
          />
          <SecondaryIndicator
            title={"Current Date"}
            amount={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].current_date &&
              data.totalDataMetrics[0].current_date.toLocaleString("en")
            }
            change={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].gr_current_date &&
              data.totalDataMetrics[0].gr_current_date.toLocaleString("en")
            }
            since={"since last date"}
            previous={
              data &&
              data.totalDataMetrics &&
              data.totalDataMetrics[0].same_time_last_day &&
              data.totalDataMetrics[0].same_time_last_day.toLocaleString("en")
            }
          />
        </>
      }
      bodyFirst={<div />}
      bodySecond={
        <div ref={targetRef}>
          <div sx={{ variant: "styles.dashboardWidgetTitle" }}>
            Accumulated data
          </div>
          <ZoomChart data={data && data.totalData} />
          <div sx={{ variant: "styles.dashboardWidgetTitle" }}>
            Daily increase
          </div>
          {variationData.length > 0 && (
            <HeatmapCalendar
              data={variationData}
              width={dimensions.width - 40}
            />
          )}
        </div>
      }
    />
  )
}

export default TotalDataDashboard
