import React from "react"
import { useColorMode } from "theme-ui"
import ReactEcharts from "echarts-for-react"

export default function HeatmapCalendar({ data, width }) {
  const [colorMode] = useColorMode()

  const getOption = () => {
    return {
      animation: false,
      tooltip: {},
      visualMap: {
        min: 0,
        max: 20000,
        calculable: false,
        orient: "horizontal",
        itemHeight: width,
        bottom: 0,
        right: 0,
        textStyle: {
          width: "100%",
        },
        color: colorMode === "dark" ? "#fff" : "#000",
        inRange: {
          color: ["rgba(60, 220, 255, 0.6)", "rgb(227, 11, 92)"],
        },
      },
      calendar: {
        left: 40,
        top: 0,
        bottom: 50,
        cellSize: ["auto", 13],
        // range: ["2019-01-01", "2020-01-31"],
        range: [data[0][0], data[data.length - 1][0]],
        splitLine: {
          lineStyle: {
            color: colorMode === "dark" ? "#000" : "#fff",
            width: 5,
            opacity: 1,
          },
        },
        itemStyle: {
          color: colorMode === "dark" ? "#000" : "#fff",
          borderWidth: 3,
          borderColor: colorMode === "dark" ? "#000" : "#fff",
        },
        yearLabel: { show: false },
        dayLabel: { show: false },
        dayLabel: {
          firstDay: 1, // start on Monday
          color: colorMode === "dark" ? "#fff" : "#000",
          fontSize: 8,
          margin: 20,
          fontFamily: "SharpSans-Medium",
        },
        monthLabel: {
          position: "end",
          color: colorMode === "dark" ? "#fff" : "#000",
          fontSize: 10,
          fontFamily: "SharpSans-Medium",
        },
      },
      series: {
        type: "heatmap",
        coordinateSystem: "calendar",
        data: data ? data : [],
      },
    }
  }

  const handleSelectDataRange = e => {
    // console.log("*** heatmap zoom")
    // console.log(e)
  }

  let onEvents = {
    datarangeselected: handleSelectDataRange,
    // 'click': this.onChartClick,
    // 'legendselectchanged': this.onChartLegendselectchanged
  }

  return (
    <ReactEcharts
      option={getOption()}
      style={{ height: "140px", width: "100%" }}
      onEvents={onEvents}
      // className="echarts-for-echarts"
    />
  )
}
