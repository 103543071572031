/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import React, { useContext } from "react"
import { Box } from "@theme-ui/components"
import {
  ArrowUpCircle,
  ArrowDownCircle,
  ArrowUp,
  ArrowDown,
  HelpCircle,
} from "react-feather"
import ContentLoader from "react-content-loader"
import GlobalContext from "../../context/global-context"

const SecondaryIndicator = ({ title, amount, change, since, previous }) => {
  const { loading } = useContext(GlobalContext)
  const [colorMode] = useColorMode()

  return (
    <Box
      bg={colorMode === "dark" ? "#0a0a0a" : "#fafafa"}
      // px={3}
      // py={3}
      p={2}
      color={colorMode === "dark" ? "white" : "black"}
      sx={{
        border: `2px solid ${colorMode === "dark" ? "#141414" : "#f5f5f5"}`,
        alignSelf: "flex-end",
        borderRadius: 8,
        width: ["100%", "32%", "32%", "32%"],
        mt: [2, 2, null, null],
        textAlign: ["center", "start", "start", "start"],
      }}
    >
      <div sx={{ variant: "styles.secondaryIndicatorTitle" }}>
        {title}{" "}
        {change ? (
          change > 0 ? (
            <ArrowUpCircle
              sx={{
                variant: "styles.indicatorUpIcon",
                color: colorMode === "dark" ? "#A6D61D" : "#8bb318",
              }}
              size={24}
            />
          ) : (
            <ArrowDownCircle
              sx={{ variant: "styles.indicatorDownIcon" }}
              size={24}
            />
          )
        ) : (
          <HelpCircle
            sx={{
              variant: "styles.indicatorUnknown",
              color: colorMode === "dark" ? "#eeeeee" : "#dadada",
            }}
            size={24}
          />
        )}
      </div>
      {/* <h2 sx={{ m: 0, textAlign: "start" }}> */}
      <div sx={{ variant: "styles.secondaryIndicatorValue" }}>
        {loading ? (
          <ContentLoader
            viewBox="0 0 150 18"
            backgroundColor={colorMode === "dark" ? "#090b0c" : "#f5f6f7"}
            foregroundColor={colorMode === "dark" ? "#161616" : "#eee"}
          >
            {/* Only SVG shapes */}
            {/* <rect x="0" y="0" rx="5" ry="5" width="70" height="70" /> */}
            <rect x="0" y="2" rx="4" ry="4" width="150" height="16" />
            {/* <rect x="80" y="40" rx="3" ry="3" width="250" height="10" /> */}
          </ContentLoader>
        ) : (
          <span sx={{ fontWeight: "slim" }}>{amount}</span>
        )}
      </div>
      <div sx={{ variant: "styles.secondaryIndicatorVariation" }}>
        {/* <h6 sx={{ m: 0 }}> */}
        {/* <Badge variant="outline" ml={1}> */}
        {loading ? (
          <ContentLoader
            viewBox="0 0 150 8"
            backgroundColor={colorMode === "dark" ? "#090b0c" : "#f5f6f7"}
            foregroundColor={colorMode === "dark" ? "#161616" : "#eee"}
          >
            {/* Only SVG shapes */}
            {/* <rect x="0" y="0" rx="5" ry="5" width="70" height="70" /> */}
            <rect x="0" y="0" rx="4" ry="4" width="150" height="8" />
            {/* <rect x="80" y="40" rx="3" ry="3" width="250" height="10" /> */}
          </ContentLoader>
        ) : (
          <>
            {change > 0 ? (
              <ArrowUp size={16} sx={{ verticalAlign: "text-bottom" }} />
            ) : (
              <ArrowDown size={16} sx={{ verticalAlign: "text-bottom" }} />
            )}{" "}
            {change} {since} ({previous})
          </>
        )}
      </div>
    </Box>
  )
}

export default SecondaryIndicator
