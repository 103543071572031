import { useState } from "react"
import { useFirebase } from "gatsby-plugin-firebase"

// import GlobalContext from "../context/global-context"
// import { getMockedData } from "./mocked"
import { manageError } from "../utils/error"

const CLOUD_FUNCTION_NAME = "getMetricsV0"

const useMetrics = ({ dataType, setLoading }) => {
  // const { data, addData } = useContext(GlobalContext)
  // const data = getDataLocalStorage(dataType)
  const [data, setData] = useState([])
  // const {
  //   setLoading,
  //   // loadedData,
  //   // setLoadedData
  // } = useGlobal() || false

  useFirebase(firebase => {
    if (data.length > 0) return
    // if (data[dataType].length > 0) return
    // if (loadedData) return

    setLoading(true)

    // if (process.env.NODE_ENV === "development") {
    //   getMockedData(dataType).then(response => {
    //     // setDataLocalStorage(dataType, response)
    //     setData(response)
    //     // const preparedResponse = {}
    //     // preparedResponse[dataType] = response
    //     // addData(preparedResponse)
    //     setLoading(false)
    //     // setLoadedData(true)
    //   })
    // } else {
    const getMetrics = firebase.functions().httpsCallable(CLOUD_FUNCTION_NAME)

    getMetrics({ queryType: dataType })
      .then(function(result) {
        // console.log(">>> useMetrics >> response from MariaDB")
        // console.log(result)
        // const preparedResponse = {}
        // preparedResponse[dataType] = result.data
        // addData(preparedResponse)
        setData(result.data)
        // setDataLocalStorage(dataType, result.data.locations)
        setLoading(false)
        // setLoadedData(true)
      })
      .catch(function(error) {
        manageError(error, `Error fetching data with "getMetrics" function`)
        setLoading(false)
      })
    // }
  }, [])

  return data
}

export default useMetrics
