import React, { useContext } from "react"

import GlobalContext from "../../context/global-context"
import Layout from "../../components/layout/layout"
import useMetrics from "../../services/useMetrics"
import TotalDataDashboard from "../../components/sections/total-data-dashboard"
import SEO from "../../components/layout/seo"

const DATA_TYPE = "total-data"

const TotalData = () => {
  const { setLoading } = useContext(GlobalContext)
  const data = useMetrics({ dataType: DATA_TYPE, setLoading })

  return (
    <Layout>
      <SEO title="Metrics - Total Data" />
      <TotalDataDashboard type="total-data" data={data} />
    </Layout>
  )
}

export default TotalData
