/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useContext } from "react"
import { useColorMode } from "theme-ui"
import { Box } from "@theme-ui/components"
import { Database } from "react-feather"
import ContentLoader from "react-content-loader"

import GlobalContext from "../../context/global-context"

const MainIndicator = ({ title, amount }) => {
  const { loading } = useContext(GlobalContext)
  const [colorMode] = useColorMode()

  return (
    <>
      {/* <Box
      // p={2}
      sx={{
        textAlign: ["center", "center", "start", "end"],
        //   border: `2px solid ${colorMode === "dark" ? "#141414" : "#f5f5f5"}`,
        //   borderRadius: 8,
      }}
    > */}
      <div
        sx={{
          variant: "styles.pageTitleSmall",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: ["center", "flex-start"],
          // justifyContent: "space-between",
        }}
      >
        <Database size={50} sx={{ mr: [3, 4, 4, 4] }} />
        <span sx={{ whiteSpace: "nowrap", verticalAlign: "text-bottom" }}>
          {title}
        </span>
      </div>
      <div
        sx={{
          // fontSize: "50px",
          width: "100%",
          m: 0,
          p: 0,
          textAlign: ["center", "start", "center", "center"],
          alignSelf: ["center", "flex-end", "center", "center"],
        }}
      >
        {loading ? (
          <ContentLoader
            viewBox="0 0 300 52"
            backgroundColor={colorMode === "dark" ? "#090b0c" : "#f5f6f7"}
            foregroundColor={colorMode === "dark" ? "#161616" : "#eee"}
          >
            {/* Only SVG shapes */}
            {/* <rect x="0" y="0" rx="5" ry="5" width="70" height="70" /> */}
            <rect x="0" y="8" rx="4" ry="4" width="250" height="52" />
            {/* <rect x="80" y="40" rx="3" ry="3" width="250" height="10" /> */}
          </ContentLoader>
        ) : (
          <span
            sx={{ variant: "styles.pageTitleSmall", alignSelf: "flex-end" }}
          >
            {amount}
          </span>
          // <span sx={{ fontWeight: "slim" }}>{amount}</span>
        )}
      </div>
      {/* </Box> */}
    </>
  )
}

export default MainIndicator
