import React from "react"
import { useColorMode } from "theme-ui"
// TODO - import EChart modules manually to reduce bundle size
import ReactEcharts from "echarts-for-react"
import echarts from "echarts/lib/echarts"

export default function ZoomChart({ data }) {
  const [colorMode] = useColorMode()

  const getOption = () => {
    return {
      grid: {
        left: 30,
        top: 0,
        right: 5,
        bottom: 70,
      },
      // animationEnabled: false,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          animation: false,
          label: {
            backgroundColor: "#505765",
          },
        },
      },
      xAxis: {
        type: "category",
        inverse: true,
        axisLine: {
          lineStyle: {
            color: colorMode === "dark" ? "#fff" : "#000",
            width: 0.2,
          },
          show: true,
        },
        axisTick: { show: false },
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false,
          textStyle: {
            baseline: "top",
            color: colorMode === "dark" ? "#fff" : "#000",
            fontSize: 10,
            fontFamily: "SharpSans-Medium",
          },
        },
        data: data
          ? data.map(row => {
              const time = new Date(row.timestamp)
              return [
                time.getFullYear(),
                time.getMonth() + 1,
                time.getDate(),
              ].join("/")
            })
          : [],
      },
      yAxis: {
        type: "value",
        nameTextStyle: { fontSize: 12, fontFamily: "SharpSans-Medium" },
        axisLine: {
          lineStyle: {
            color: colorMode === "dark" ? "#fff" : "#000",
            width: 0.2,
          },
          show: true,
        },
        axisTick: { show: false },
        axisLabel: {
          showMinLabel: false,
          showMaxLabel: false,
          textStyle: { fontSize: 8 },
          fontFamily: "SharpSans-Medium",
          formatter: function(value, index) {
            return `${value / 1000000} M`
          },
        },
        splitLine: { show: false },
      },
      dataZoom: [
        {
          // This dataZoom component controls x-axis by dafault
          type: "slider", // this dataZoom component is dataZoom component of slider
          start: 0, // the left is located at 10%
          end: 100, // the right is located at 60%
          fillerColor:
            colorMode === "dark"
              ? "rgba(69, 69, 69, 0.25)"
              : "rgba(187,187,187,0.25)",
          dataBackground: {
            lineStyle: {
              color: colorMode === "dark" ? "white" : "black",
              width: 1,
              opacity: 1,
            },
            areaStyle: {
              opacity: 0,
            },
          },
          borderColor:
            colorMode === "dark"
              ? "rgba(69, 69, 69, 0.25)"
              : "rgba(187,187,187,0.25)",
          textStyle: {
            fontSize: 8,
            fontFamily: "SharpSans-Medium",
            color: colorMode === "dark" ? "#fff" : "#000",
          },
        },
        {
          // This dataZoom component controls x-axis by dafault
          type: "inside", // this dataZoom component is dataZoom component of slider
          start: 0, // the left is located at 10%
          end: 100, // the right is located at 60%
        },
      ],
      series: [
        {
          data: data ? data.map(row => row.n) : [],
          type: "line",
          smooth: true,
          symbol: "none",
          sampling: "average",
          lineStyle: {
            color: colorMode === "dark" ? "white" : "black",
            width: 3,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(227, 11, 92)",
              },
              {
                offset: 1,
                color: "rgb(60, 220, 255)",
              },
            ]),
          },
        },
      ],
    }
  }

  const handleDataZoom = e => {
    // console.log("*** chart zoom")
    // console.log(e)
  }

  let onEvents = {
    dataZoom: handleDataZoom,
    // 'click': this.onChartClick,
    // 'legendselectchanged': this.onChartLegendselectchanged
  }

  return (
    <ReactEcharts
      option={getOption()}
      style={{ height: "230px", width: "100%" }}
      onEvents={onEvents}
      // className="echarts-for-echarts"
    />
  )
}
